<template>
    <div class="md:-mt-3">
        <loading-modal :isShow="isLoading"/>
        <div v-if="isLoading == false">
            <div>
                <div class="header w-full h-32 bg-blue-500 rounded-3xl rounded-t-none px-3 pt-5">
                    <div class="flex items-center">
                        <div class="flex-none flex-grow">
                        </div>
                        <div class="mb-2 w-full">
                            <div class="bg-white p-1 rounded-md grid grid-cols-2 shadow md:w-2/4 w-3/4 mx-auto">
                                <router-link :to="{
                                    name : 'QuizResultDetail',
                                    params : {
                                        'singkatan_kategori': $route.params.singkatan_kategori,
                                        'id_paket_kuis_skor': paket_kuis_skor.id_paket_kuis_skor
                                    }
                                }" class="bg-white rounded text-center text-gray-800 p-2 text-sm">
                                    Nilai
                                </router-link>

                                <router-link :to="{
                                    name : 'QuizReviewDetail',
                                    params : {
                                        'singkatan_kategori': $route.params.singkatan_kategori,
                                        'id_kuis'           : paket_kuis_skor.id_kuis
                                    }
                                }" class="bg-blue-500 rounded text-center text-white p-2 text-sm">
                                    Pembahasan
                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="hero px-4 -mt-11">
                    <div class="bg-white w-full rounded-2xl p-3 h-24 shadow-lg flex flex-col justify-center">
                        <h2 class="text-center text-md font-semibold text-gray-600 uppercase font-custom-1">PEMBAHASAN</h2>
                        <h2 class="text-center text-2xl font-semibold text-gray-800 uppercase font-custom-1">{{ pembahasan.paket_kuis_skor.kuis.judul_kuis }}</h2>
                        <p class="text-center text-md text-gray-600">{{ soal.sub_kategori }}</p>
                    </div>
                </div>
            </div>


            <div class="my-3 mb-6 px-4 ">

                 <div class="mb-2">
                    <div class="bg-gray-200 px-2 py-1 w-auto inline-block rounded ml-2" v-if="soal.nama_bab != null ">
                        <div class="font-semibold text-gray-800 text-sm">{{ soal.nama_bab }}</div>
                    </div>
                </div>

                <div class="mb-2 border-b-2 flex space-x-2 items-center pb-2">
                    <div class="font-bold flex-none">Nomor Soal</div>
                    <div class="flex-grow">
                        <div class="rounded-full bg-blue-400 w-6 h-6 font-semibold text-white items-center flex align-middle mr-2">
                            <div class="mx-auto">{{ soal.urutan }}</div>
                        </div>
                    </div>
                    <router-link :to="{name: 'ReportSoal', params : {'id_kuis_soal' : soal.id_kuis_soal, 'singkatan_kategori' : this.$route.params.singkatan_kategori, 'id_kuis' : this.$route.params.id_kuis}, query : { urutan : soal.urutan }}" class="p-1 px-2 bg-gray-100 rounded-lg text-gray-800 ml-auto w-auto flex space-x-2 transition-all hover:bg-gray-300 flex-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Laporkan Soal
                    </router-link>
                </div>

                <div class="mb-2">
                    <div class="mb-4" v-html="soal.pertanyaan"></div>
                </div>

                <div class="opsi bg-white px-2 py-2 rounded-lg  border-2 mb-3">
                    <div class="w-full text-sm md:text-base">
                        <div class="flex space-x-1 mb-1 font-semibold">
                            <div class="p-0.5 flex-grow bg-blue-300 px-2 py-0.5 rounded">
                                Pilihan Jawaban
                            </div>
                            <div class="p-0.5 bg-blue-300 px-2 py-0.5 rounded text-center" style="width: 80px;">
                                Point
                            </div>
                        </div>

                        <div class="flex space-x-1 mb-1" v-for="(opsi, index_opsi) in soal.opsi" :key="opsi.id_kuis_soal_opsi">
                            <div class="flex-grow px-2 py-1 rounded flex space-x-1" 
                                :class="
                                        opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-red-100'
                                            )
                                        : 
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-gray-100'
                                            )
                                ">
                                <div class="h-6 w-6 rounded-md flex-none text-center flex items-center"
                                    :class="
                                        opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-300' : 'bg-red-300'
                                            )
                                        : 
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-300' : 'bg-gray-300'
                                            )

                                    "
                                >
                                    <div class="text-center mx-auto">{{ option_list[index_opsi] }}</div>
                                </div>
                                <div v-html="opsi.isi"></div>
                            </div>

                            <div style="width: 80px;" class="flex-none px-2 py-1 rounded text-center"
                                    :class="
                                        opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-red-100'
                                            )
                                        :
                                            (
                                                id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-gray-100'
                                            )

                                "
                                >{{ Number(opsi.bobot) }}
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="flex mb-3 space-x-1">
                    <div class="bg-white px-2 py-2 rounded-lg border-2 border-blue-500 text-sm md:text-base text-center flex-grow w-1/3">
                        <p class="text-gray-700 text-xs md:text-base">Persen Jawaban Benar</p>
                        <h4 class="text-xl md:text-4xl text-green-500 font-bold">{{ (soal.jumlah_benar / soal.jumlah_total * 100).toFixed(2) }}%</h4>
                    </div>
                    <div class="bg-white px-2 py-2 rounded-lg border-2 border-blue-500 text-sm md:text-base text-center  flex-grow w-1/3">
                        <p class="text-gray-700 text-xs md:text-base">Jawaban Salah Terbanyak</p>
                        <h4 class="text-xl md:text-4xl text-red-500 font-bold">{{ label_sering_salah == null ? '-' : label_sering_salah }}</h4>
                    </div>
                    <div class="bg-white px-2 py-2 rounded-lg border-2 border-blue-500 text-sm md:text-base text-center flex-grow w-1/3">
                        <p class="text-gray-700 text-xs md:text-base">Waktu yang Diperlukan</p>
                        <h4 class="text-xl md:text-4xl text-blue-500 font-bold">
                            {{ total_waktu.minute }}m : {{ total_waktu.second }}s
                        </h4>
                    </div>
                </div>

                <div class="pembahasan bg-white px-2 py-2 rounded-lg border-2 text-sm md:text-base mb-3">
                    <div class="font-semibold">Pembahasan</div>
                    <div v-html="soal.pembahasan"/>
                </div>

                <div class="pembahasan bg-white px-2 py-2 rounded-lg border-2 text-sm md:text-base mb-3" v-if="soal.video_pembahasan != null">
                    <div class="font-semibold mb-2">Video Pembahasan</div>
                    <div v-if="isMobile()">
                        <div class="w-64 m-auto text-center flex flex-col items-center space-y-2 mb-2">
                            <div>
                                <img src="@/assets/images/video.svg" class="w-1/3 mx-auto"/>
                            </div>
                            <h2 class="text-sm">Pembahasan disajikan dalam bentuk video</h2>
                            <button @click="previewVideo(soal.video_pembahasan)" class="p-1 px-2 bg-yellow-400 rounded-md text-white  focus:outline-none">Lihat Video</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="rounded-md overflow-hidden">
                            <vue-plyr :options="options">
                                <video controlsList="nodownload" controls>
                                    <source :src="soal.video_pembahasan"/>
                                </video>
                            </vue-plyr>
                        </div>
                    </div>
                </div>

                <div class="md:mt-6">
                    <pembahasan-navigation :navigation="navigation" :urutanAktif="page" :getPertanyaan="getPertanyaan" :activeTab="activeTab" :changeActiveTab="changeActiveTab"/>
                </div>
                
                <div class="h-10 md:h-12"/>

                <div class="py-2 px-1 bg-white w-full fixed bottom-12 md:bottom-0 right-0 left-0 border-t-2 border-gray-100">
                    <div class="max-w-4xl grid grid-cols-2 mx-auto space-x-1">
                        <button
                            @click="getPertanyaan(page - 1)"
                            class="relative justify-center py-2 px-4 border border-transparent text-sm 
                            font-medium rounded-md text-gray-600 bg-gray-100 
                            focus:outline-none"
                            :class="page == 1 ? 'invisible' : ''" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                            </svg>
                        </button>
                        <button
                            @click="getPertanyaan(page + 1)"
                            class="justify-center py-2 px-4 border border-transparent text-sm 
                            font-medium rounded-md text-gray-600 bg-gray-100 
                            focus:outline-none"
                            :class="page == total_pertanyaan ? 'opacity-0' : ''">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </button>

                    </div>
                </div>

            </div>
            
        </div>
   </div>
</template>

<script>
import LoadingModal from '../../components/LoadingModal.vue';
    import PembahasanNavigation from '../../components/PembahasanNavigation.vue';
    import { errorHandler, isMobile, showErrorNotif } from '../../helpers/Tools';
    import moment from 'moment'

    export default {
        name: 'QuizReviewDetail',
        data(){
            return {
                isLoading              : true,
                pembahasan             : null,
                soal                   : null,
                total_waktu : {
                    minute: '00',
                    second: '00',
                },
                navigation             : [],
                page                   : this.$route.query.urutan == null ? 1: this.$route.query.urutan,
                option_list            : ['A', 'B', 'C', 'D', 'E'],
                label_sering_salah     : null,
                id_opsi_tertinggi_akhir: null,
                paket_kuis_skor        : null,
                total_pertanyaan       : 0,
                options                : { quality: { default: '1080p' } },
                activeTab       : 0
            }
        },
        components : {
            PembahasanNavigation, LoadingModal
        },
        mounted() {
			this.getPembasahan();
		},
		methods : {
            isMobile() {
                return isMobile();
            },
            async getPembasahan() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getPembahasanPerKuis", {
                        id_kategori: this.$route.params.singkatan_kategori,
                        id_kuis    : this.$route.params.id_kuis,
                        page       : this.page
                    });
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.pembahasan = result.data;
                        this.soal       = result.data.pembahasan.data[0];
                        this.navigation = result.data.navigasi;
                        this.paket_kuis_skor = result.data.paket_kuis_skor;
                        this.total_pertanyaan = result.data.pembahasan.total;

                        let id_kuis_soal_opsi_salah = this.soal.opsi_salah;
                        this.label_sering_salah = null;
                        if(id_kuis_soal_opsi_salah != null) {
                            for(let i=0; i < this.soal.opsi.length; i++) {
                                let opsi = this.soal.opsi[i];
                                if(id_kuis_soal_opsi_salah.id_kuis_soal_opsi == opsi.id_kuis_soal_opsi) {
                                    this.label_sering_salah = this.option_list[i];
                                }
                            }
                        }

                        let id_opsi_tertinggi = null;
                        let nilai_opsi_tertinggi = 0;
                        for(let i=0; i < this.soal.opsi.length; i++) {
                            let opsi = this.soal.opsi[i];
                            if(nilai_opsi_tertinggi < opsi.bobot) {
                                nilai_opsi_tertinggi = opsi.bobot;
                                id_opsi_tertinggi = opsi.id_kuis_soal_opsi
                            }
                        }

                        this.id_opsi_tertinggi_akhir = id_opsi_tertinggi;

                        // soal 
                        if(this.soal.total_waktu == null) {
                            this.total_waktu =  {
                                minute: '00',
                                second: '00',
                            };
                        } else {    

                            let milliseconds = this.soal.total_waktu * 10000;
                            let tempTime = moment.duration(milliseconds)
                            this.total_waktu =  {
                                minute: tempTime.minutes().pad(2),
                                second: tempTime.seconds().pad(2),
                            };
                        }

                        if(this.soal.video_pembahasan != null) {
                            let url = await this.$store.dispatch('addTokenToUrl', this.soal.video_pembahasan);
                            this.soal.video_pembahasan = url;
                        }

                        this.changeActiveTab(this.soal.id_sub_kategori);

                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            getPertanyaan(page) {
                page = Number(page)
                this.page = page;
                this.getPembasahan();
            },

            async previewVideo(url) {
                if(typeof window.flutter_inappwebview != 'undefined') {
                    window.flutter_inappwebview.callHandler('instancy.videoViewer', url);
                } else {
                    window.open(url, '_blank');
                }
            },
            changeActiveTab(id_sub_kategori) {
                this.activeTab = id_sub_kategori
            },
           
        }

       
    }
</script>
<style>
    .pembahasan img, .pembahasan iframe {
        max-width: 100%;
    }
</style>
