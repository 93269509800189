<template>
    <div class="navigation">

        <div class="flex items-stretch space-x-1 mb-3">
            <div @click="changeActiveTab(subKategori.id_sub_kategori)" class="cursor-pointer rounded-sm px-3 py-1 flex-grow text-center" :class="activeTab == subKategori.id_sub_kategori ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800' " v-for="(subKategori, index ) in navigation" :key="index">{{ subKategori.singkatan }}</div>
        </div>

        <div v-for="(subKategori, index ) in navigation" :key="index">
            <div class="grid grid-cols-10 gap-1 mt-2" v-if="subKategori.id_sub_kategori == activeTab">
                <div class="cursor-pointer flex font-semibold rounded-md h-8 md:h-14 w-8 md:w-14 items-center border-2 mb-1 mx-auto" 
                    @click="getPertanyaan(data.urutan)"
                    v-for="(data, index) in subKategori.navigation" :key="index"
                    :class="
                        (data.bobot > 0 ? 
                            'bg-green-100 hover:text-green-800 text-green-600' :
                            'bg-red-100 hover:text-red-800 text-red-600' 
                        )
                        + ' ' +
                        (data.urutan == urutanAktif ? 'border-yellow-500' : 'border-transparent') 
                    ">
                    <div class="text-center w-full">{{ data.urutan }}</div>
                </div>
            </div>
        </div>

        <!-- <div class="grid grid-cols-10 gap-1 mt-2">

            <div class="cursor-pointer flex font-semibold rounded-md h-8 md:h-14 w-8 md:w-14 items-center border-2 mx-auto" 
                @click="getPertanyaan(data.urutan)"
                v-for="(data, index) in navigation" :key="index"
                :class="
                    (data.bobot > 0 ? 
                        'bg-green-100 hover:text-green-800 text-green-600' :
                        'bg-red-100 hover:text-red-800 text-red-600' 
                    )
                    + ' ' +
                    (data.urutan == urutanAktif ? 'border-yellow-500' : 'border-transparent') 
                "
                >
                <div class="text-center w-full">{{ data.urutan }}</div>
            </div>

        </div> -->
    </div>
</template>
<script>
export default {
    name: 'TestNavigation',
    props: {
        navigation : {
            type : Array,
        },
        changeActiveTab : {
            type : Function
        },
        activeTab : {
            type : Number
        },
        urutanAktif : {
            type : Number
        },
        getPertanyaan : {
            type : Function
        }
    }
}
</script>
